<template>
    <v-container fluid>
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    :value="importacionMaestro.numero"
                                    label="Número de Importación"
                                    readonly
                                    prepend-icon="pin"
                                />

                                <v-text-field
                                    :value="importacionMaestro.unidad_operativa"
                                    label="Unidad Operativa"
                                    readonly
                                    prepend-icon="business"
                                />

                                <v-text-field
                                    :value="importacionMaestro.archivo_nombre"
                                    label="Archivo"
                                    readonly
                                    prepend-icon="attach_file"
                                />
                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    :value="importacionMaestro.nombres_apellidos"
                                    label="Importada por"
                                    readonly
                                    prepend-icon="person"
                                />

                                <v-text-field
                                    :value="importacionMaestro.fecha_crea | fechaESPHora"
                                    label="Fecha de Importación"
                                    readonly
                                    prepend-icon="today"
                                />

                                <v-text-field
                                    :value="importacionMaestro.fecha_actualiza | fechaESPHora"
                                    label="Fecha de Procesamiento"
                                    readonly
                                    prepend-icon="today"
                                />
                            </v-col>
                        </v-row>

                        <v-expansion-panels accordion v-model="panel" multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-toolbar-title>Datos de la Importación</v-toolbar-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>

                                        <v-col cols="6" sm="6" md="6" lg="6" xl="6">

                                            <v-btn color="info" outlined small @click="importacionDetalleGet()">
                                                <v-icon>refresh</v-icon>
                                                Refrescar
                                            </v-btn>

                                        </v-col>

                                        <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                            <v-text-field
                                                v-model="search"
                                                hide-details
                                                label="Buscar por Estudiante, Curso, Registro y pulse en el botón."
                                                clearable>
                                                <template v-slot:append>
                                                    <v-btn
                                                        color="secondary" small outlined
                                                        @click="importacionDetalleBuscar()">
                                                        <v-icon>search</v-icon>
                                                    </v-btn>
                                                </template>
                                                >
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-data-table
                                                dense
                                                class="elevation-1"
                                                :headers="tableHeaders"
                                                :items="importacionDetalle"
                                                :loading="loading"
                                                item-key="curso_id"
                                                fixed-header
                                                height="65vh"
                                                :hide-default-footer="true"
                                                disable-pagination
                                            >
                                                <template v-slot:item="{ item }">
                                                    <tr>
                                                        <td class="text-left">{{ item.numero_identidad }}</td>
                                                        <td class="text-left">{{ item.nombres_apellidos }}</td>
                                                        <td class="text-left">{{ item.registro }}</td>
                                                        <td class="text-left">{{ item.curso }}</td>
                                                        <td class="text-left">{{ item.fecha_inicio | fechaESP }}</td>
                                                        <td class="text-left">{{
                                                                item.fecha_finalizacion | fechaESP
                                                            }}
                                                        </td>
                                                        <td class="text-left">{{
                                                                item.fecha_certificado | fechaESP
                                                            }}
                                                        </td>
                                                        <td class="text-center">
                                                            <v-chip
                                                                :color="(item.aprobado) ? 'success' : 'error'"
                                                                outlined
                                                                pill
                                                            >
                                                                {{ item.nota }}
                                                            </v-chip>
                                                        </td>
                                                        <td class="text-left">{{ item.tutor }}</td>
                                                    </tr>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-alert dense type="warning" outlined class="text-center mt-3">
                                                        No existen registros.
                                                    </v-alert>
                                                </template>

                                            </v-data-table>
                                            <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina"
                                                        v-if="pagination.total"/>
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="importacionMaestro.puede_eliminar">
                                <v-expansion-panel-header>
                                    <v-toolbar-title>
                                        ¿Quiere Eliminar esta Importación <b>{{importacionMaestro.numero}}</b>?. Esta operación Eliminará toda la Data Importada.
                                    </v-toolbar-title>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-form ref="form" v-model="valid">

                                        <v-text-field
                                            v-model="importacionModel.observacion"
                                            :label="importacionForm.observacion.label"
                                            :rules="importacionForm.observacion.rules"
                                            required
                                            prepend-icon="description"
                                        />

                                    </v-form>
                                    <v-divider class="mt-1"></v-divider>
                                    <div class="text-center py-3">

                                        <v-btn small color="error" :loading="loading"
                                               @click.stop="eliminarImportacion()" :disabled="!valid">
                                            <v-icon>delete</v-icon>
                                            SI, Eliminar, estoy de Acuerdo
                                        </v-btn>

                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>


                    </v-card-text>
                    <v-divider class="mt-1"></v-divider>
                    <v-card-actions class="justify-center py-3">

                        <v-btn small outlined @click="regresar">
                            <v-icon>arrow_back</v-icon>
                            Regresar
                        </v-btn>

                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import Auth from "@/services/auth";
const Pagination = () => import("@/pages/Components/Pagination.vue");

export default {
    name: "AdministracionImportacionesVer",
    components: {Pagination},
    data() {
        return {
            permisos: [],
            search: "",
            pagination: {},
            importacionDetalle: [],
            importacionMaestro: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            valid: true,
            loading: false,
            panel: [0],
            tableHeaders: [
                {
                    text: 'Identidad',
                    value: 'numero_identidad',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Nombres Participante',
                    value: 'nombres_apellidos',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Registro',
                    value: 'registro',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Curso',
                    value: 'curso',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Fecha Inicio',
                    value: 'fecha_inicio',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Fecha Fin',
                    value: 'fecha_fin',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Fecha Certificado',
                    value: 'fecha_certificado',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Nota',
                    value: 'nota',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Tutor',
                    value: 'tutor',
                    align: 'left',
                    sortable: false,
                },
            ],
            importacionModel: {
                importacion_id: null,
                observacion: null,
            },
            importacionForm: {
                observacion: {
                    label: "Observación",
                    placeholder: 'Escriba una Observación para Eliminar',
                    rules: [
                        (v) => !!v || 'Campo requerido',
                        (v) => !!v && v.length >= 10 || `La Observación tiene que ser mayor a 10 caracteres.`,
                    ],
                },
            },
        }
    },
    methods: {
        importacionDetalleBuscar() {
            this.pageActual = 1;
            this.importacionDetalleGet();
        },
        importacionDetalleGet() {
            this.loading = true;

            let params = {
                importacion_id: this.importacionModel.importacion_id,
                busqueda: this.search,
                page: this.pageActual,
                rowsPage: this.rowsPage
            };

            window.axios.get('/cursos/por_importacion', {params}).then(response => {
                if (response.data.success) {

                    let data = response.data.data;
                    this.importacionDetalle = data.cursos.data;
                    this.pagination = data.pagination;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        importacionMaestroGet() {
            this.loading = true;

            let params = {
                importacion_id: this.importacionModel.importacion_id
            };

            window.axios.get('/importaciones/maestro', {params}).then(response => {
                if (response.data.success) {

                    let data = response.data.data;
                    this.importacionMaestro = data;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        eliminarImportacion() {
            this.loading = true;

            window.axios.post('/importaciones/delete', this.importacionModel).then(response => {
                if (response.data.success) {

                    this.$toast.success(response.data.message);
                    this.regresar();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.importacionDetalleGet();
        },
        verificarToken() {

            if (typeof this.$route.query.token !== "undefined") {
                try {
                    let token = this.$route.query.token;
                    this.importacionModel.importacion_id = window.atob(token);

                    this.importacionMaestroGet();
                    this.importacionDetalleGet();
                } catch (e) {
                    console.log(e);
                    this.$toast.error("El parametro para Ver no es valido.");
                    this.regresar();
                }

            } else {
                this.$toast.error("Debe enviar un parametro para Ver.");
                this.regresar();
            }
        },
        regresar() {
            this.$router.go(-1)
        }
    },
    created() {
        Auth.checkPermisos("/administracion/importaciones").then((res) => {
            this.permisos = res;
            this.verificarToken();
        });

    }
}
</script>

<style>

</style>
